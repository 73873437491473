.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: #FFFFFF;
    color: #242424;
    border: 1px solid #FFFFFF;
}

.btn--outline {
    background-color: #BA233B;
    color: #FFFFFF;
    padding: 8px 20px;
    border: 1px solid #FFFFFF;
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover,
.btn--large:hover {
    background: #242424;
    color: #FFFFFF;
    transition: all 0.3s ease-out;
}