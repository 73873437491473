.introductions {
    padding: 4rem;
    background: #FFFFFF;
}

.introductions__container {
    display: flex;
    display: -webkit-box;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    flex-flow: column;
    align-items: center;
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
}

.introductions__wrapper {
    position: relative;
}
  
.introductions__items {
    margin-bottom: 0px;
    list-style-type: none;
    padding-left: 0px;
}

.introductions__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    overflow: hidden;
    text-decoration: none;
}

.introductions__item__info {
    background-color: transparent;
}
.introductions__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
    text-align: justify;
}
  
@media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
}
  
@media only screen and (min-width: 1024px) {
    .introductions__items {
      display: flex;
    }
}
  
@media only screen and (max-width: 1024px) {
    .introductions__item {
      margin-bottom: 2rem;
    }
}