* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.about,
.coaching,
.blog,
.book,
.contact {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.about {
  background-image: url('../public/images/about_img.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #FFFFFF;
  font-size: 72px;
  margin-bottom: 0;
}

.coaching {
  background-image: url('../public/images/about_img.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #FFFFFF;
  font-size: 72px;
  margin-bottom: 0;
}

.blog {
  background-image: url('../public/images/about_img.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #FFFFFF;
  font-size: 72px;
  margin-bottom: 0;
}

.book {
  background-image: url('../public/images/about_img.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #FFFFFF;
  font-size: 72px;
  margin-bottom: 0;
}

.contact {
  background-image: url('');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #FFFFFF;
  font-size: 72px;
  margin-bottom: 0;
}