.abouts {
    padding: 4rem;
    background: #FFFFFF;
}

.abouts__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
}

.abouts__wrapper {
    position: relative;
}

.abouts__experience {
    list-style-type: none;
    padding-left: 0px;
    text-align: justify;
}
  
.abouts__items {
    margin-bottom: 24px;
    list-style-type: none;
    padding-left: 0px;
}

.abouts__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
}

.abouts__item__info {
    background-color: #FFFFFF;
}
.abouts__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
    text-align: justify;
}
  
@media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
}
  
@media only screen and (min-width: 1024px) {
    .abouts__items {
      display: flex;
    }
}
  
@media only screen and (max-width: 1024px) {
    .abouts__item {
      margin-bottom: 2rem;
    }
}