.blogs__cards {
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
}
.card-title {
    font-size: 28px;
}
.card-user {
    font-size: 13px;
}
.blogs__button__link {
    color: #FFFFFF;
    text-transform: uppercase;
    background-color: #BA233B;
    border: 1px solid #BA233B;
    text-decoration: none;
    padding: 10px 12px 10px 12px;
    transition: all 0.2s linear;
}

.blogs__button__link:hover {
    color: #BA233B;
    text-transform: uppercase;
    background-color: #FFFFFF;
    text-decoration: none;
    border: 1px solid #BA233B;
    padding: 10px 12px 10px 12px;
    transition: all 0.2s linear;
}