.top_footers {
    padding: 4rem;
    background: #BA233B;
}

.top_footers__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
}

.top_footers__wrapper {
    position: relative;
}

.top_footers__items {
    list-style-type: none;
    padding-left: 0px;
}

.top_footers__button__link {
    color: #FFFFFF;
    text-transform: uppercase;
    background-color: #40474d;
    text-decoration: none;
    padding: 20px 22px 20px 22px;
    transition: all 0.2s linear;
}

.top_footers__button__link:hover {
    color: #BA233B;
    text-transform: uppercase;
    background-color: #FFFFFF;
    text-decoration: none;
    padding: 20px 22px 20px 22px;
    transition: all 0.2s linear;
}
  
.top_footers__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    overflow: hidden;
    text-decoration: none;
}

.top_footers__item__text {
    color: #FFFFFF;
    font-size: 38px;
    text-align: center;
}
  
@media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
}
  
@media only screen and (min-width: 1024px) {
    .top_footers__items {
      display: flex;
    }
}
  
@media only screen and (max-width: 1024px) {
    .top_footers__item {
      margin-bottom: 2rem;
    }
}