.index-container {
    background: url('../../public/images/index_img.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
}
.index-container > h1 {
    margin-bottom: 15px;
    color: #FFFFFF;
    font-size: 72px;
    font-weight: 400;
}
.index-container > h6 {
    margin-bottom: 15px;
    color: #BA233B;
    font-size: 27px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}
@media screen and (max-width: 960px) {
    .index-container > h1 {
        font-size: 42px;
    }
}
@media screen and (max-width: 768px) {
    .index-container > h1 {
        font-size: 22px;
    }
    .index-container > h6 {
        font-size: 14px;
    }
}